<template>
  <div>
    <iframe width="100%" height="100%" src="https://api.building-energy.org/api-docs-edoc/"></iframe>
  </div>
</template>
<script>
import NavGnb from '@/layouts/components/NavGnb.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import vSelect from 'vue-select';
import { BFormSelect, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  data() {
    return {};
  },
};
</script>
<style>
iframe {
  display: block; /* iframes are inline by default */
  background: #000;
  border: none; /* Reset default border */
  height: 100vh; /* Viewport-relative units */
  width: 100vw;
}
</style>
